import type { Dictionary, Market, Tickers } from 'ccxt'
import { getFileType } from '~components/code/file-explorer/explorer-utils'
import { CodingType } from '~components/code/type'
import type { Profile } from '~components/profile/slice'
import { CodeCommentThreadState, CodeComments } from '~data/comment/comment-type'
import type { Execution, ExecutionId, ExecutionSettings, ExecutionSummary } from '~data/execution/execution-type'
import type { File, FileId, FileVersion } from '~data/file/file-type'
import { SortingDirection, ViewMode } from '~data/listing/listing.types'
import type { Project, ProjectPermission } from '~data/projects'
import assertUnreachable from '~utils/assertUnreachable'
import { AuthorizedRequestParams, authorizedJsonFetch } from '~utils/authorized-fetch'
import { getFileParentPath } from '~utils/file'
import { captureError } from '~utils/monitoring'
import type {
  AIGeneratedCode,
  AIGeneratedEntry,
  AIGeneratedPublicationsQuery,
  AdminActivityLog,
  AdminFeature,
  AdminFetchUserActionLogsOption,
  AdminJupyterUsageStat,
  AdminRatePlan,
  AdminRole,
  AdminSubscriptionStatsResult,
  AdminUser,
  AdminUserActionLog,
  AdminWalletTransaction,
  AppStatus,
  Backend,
  BackendCellEmbedConnection,
  BackendCellEmbedConnectionMode,
  BackendCollaborationConnection,
  BackendCollaboratorLobbyConnection,
  BackendContainerConnection,
  BackendExecutionConnection,
  BackendFileChannelConnection,
  BackendNotebookConnection,
  BackendNotebookConnectionParameters,
  BackendNotificationsConnection,
  BackendPermissionInfo,
  BackendProjectFilesConnection,
  BackendProjectImportConnection,
  BackendSelectInfo,
  BackendSlackChannel,
  BackendSortingInfo,
  BackendSortingOption,
  BackendTrackingConnection,
  BackendTradingTransactionsConnection,
  Balance,
  BrowseSyncRemoteResponse,
  ClonedFileIds,
  CursorPaginatedResponse,
  DocumentationSection,
  Environments,
  EventEntry,
  ExchangeConnections,
  ExternalPublishSettings,
  FeedEntry,
  FileEmbed,
  FileUploadItem,
  GitHubConnection,
  Invite,
  NewsroomEntry,
  NotebookAnalysis,
  NotebookComment,
  NotificationEntry,
  PaginatedResult,
  ProjectAccessRequest,
  ProjectInvite,
  ProjectPaginatedResult,
  ProjectPaginationOptions,
  ProjectSyncResponse,
  ProjectTeamAccess,
  ProjectUserAccess,
  Publication,
  PublicationCountsResult,
  PublicationOfProject,
  PublicationPaginatedResult,
  PublicationSchedule,
  RamTiersResponse,
  RatePlan,
  Referral,
  ReferralUser,
  ResponseCollection,
  ScheduledExecution,
  ScheduledExecutionPatch,
  ScheduledNotebook,
  Secret,
  Self,
  SignupStat,
  Subscription,
  SubscriptionStats,
  Tag,
  TagSubscription,
  Team,
  TeamDiscovery,
  TeamInvite,
  TeamJoinRequest,
  TeamMember,
  TeamMemberPermission,
  TeamProject,
  Tip,
  TradingAsset,
  TradingAssetData,
  TradingBalance,
  TradingBotChannel,
  TradingTransaction,
  UserAboutPage,
  UserFollow,
  UserInfo,
  UserServer,
  UserToken,
  WalletBalances,
  WalletState,
  WalletUnitPurchaseInfo,
  WalletUnitPurchaseSession,
} from '../common/backend'
import PapillonCollaborationConnection from './connections/collaboration'
import PapillonContainerConnection from './connections/container'
import PapillonExecutionConnection from './connections/execution'

// This is because we don't want to ask the backend for project data too often
const PROJECTS_DEBOUNCE_MS = 30000

type ListFileResponse = Array<{ file: File; name: string; type: 'directory' | 'file' }>

/**
 * Paginate list, page starting 1
 */
function paginate(items: Array<Project>, perPage: number, page: number): ProjectPaginatedResult {
  const pageFromZero = page - 1

  return {
    entries: items.slice(pageFromZero * perPage, pageFromZero * perPage + perPage),
    perPage,
    page,
    totalPages: Math.ceil(items.length / perPage),
    tags: [],
  }
}

export function searchProjects(items: Array<Project>, opts: ProjectPaginationOptions): ProjectPaginatedResult {
  const { mode, filter, sortingField, authorId, ownerId, sortingDirection } = opts
  const page = opts?.page || 0
  const limit = opts?.perPage || 100

  const itemFilter = (x: Project) => {
    const hiddenByPrivacy = mode !== ViewMode.private && x.parentId
    const hiddenByAuthor = authorId && x.userId !== authorId
    const hiddenByOwner = ownerId && x.userId !== ownerId

    return !hiddenByOwner && !hiddenByAuthor && !hiddenByPrivacy && (!filter || x.name.toLowerCase().includes(filter.toLowerCase()))
  }
  const filteredItems = items.filter(itemFilter)

  const sortFunction = (a: Project, b: Project) => {
    let num = 0

    switch (sortingField) {
      case 'createdAt':
        num = b.createdAt - a.createdAt
        break
      case 'updatedAt':
        num = (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
        break
      case 'name':
        if (b.name < a.name) {
          num = -1
        }
        if (b.name > a.name) {
          num = 1
        }
        break

      case 'totalRating':
        num = b.totalRating - a.totalRating
        break
      default:
        num = b.createdAt - a.createdAt
    }
    if (sortingDirection === SortingDirection.asc) {
      num = -num
    }
    if (num === 0) {
      if (b.name < a.name) {
        num = -1
      }
      if (b.name > a.name) {
        num = 1
      }
    }
    return num
  }

  filteredItems.sort(sortFunction)

  const nonUserItems = filteredItems.filter(x => !!x.publicUrl && x.publicGroup === 'all')
  const userItems = filteredItems.filter(x => !x.teamShared && !x.isPublic)
  const teamItems = filteredItems.filter(x => x.teamShared)

  switch (mode) {
    case ViewMode.public:
      return paginate(nonUserItems, limit, page)
    case ViewMode.shared:
      return paginate(teamItems, limit, page)
    case ViewMode.private:
      return paginate(userItems, limit, page)
    default:
      return paginate(filteredItems, limit, page)
  }
}

function encodeThreads(threads?: CodeComments) {
  if (threads) {
    return threads
      .filter(thread => thread.state !== CodeCommentThreadState.erased)
      .map(thread => ({
        threadId: thread.id,
        row: thread.line,
      }))
  }

  return undefined
}

function mapPermissions(permissions?: Array<ProjectPermission>, isOwner?: boolean): Array<ProjectPermission> {
  const updatedPermissions = [...(permissions || [])]

  updatedPermissions.push('view')

  if (isOwner) {
    updatedPermissions.push('edit')
    updatedPermissions.push('share')
    updatedPermissions.push('execute')
  }

  return updatedPermissions
}

function mapFileVersion(data: any): FileVersion {
  const version = { ...data }

  version.fileId = data.notebookId
  version.threads = data.threadPositions
    ? data.threadPositions.map((position: any) => ({
        id: position.threadId,
        line: position.row,
      }))
    : []

  delete version.threadPositions
  delete version.notebookId
  return version
}

interface PapillonProjectShareRequest {
  isPublic: boolean
  publicGroup: 'all' | 'teams'
  users: Record<string, Array<string>>
  teams: Record<string, Array<string>>
}

interface PapillonBackendCache {
  cachedProjects: Array<Project>
  cachedProjectsDate: number
  userId?: string
}

export default class PapillonBackend implements Backend {
  prefix: string

  cache: PapillonBackendCache

  signal?: AbortSignal

  networkBackend?: 'xhr' | 'fetch'

  uploadProgress?: AuthorizedRequestParams['onProgress']

  constructor(prefix: string) {
    this.prefix = prefix
    this.cache = {
      cachedProjects: [],
      cachedProjectsDate: 0,
    }
  }

  async fetchPublicationCounts(): Promise<PublicationCountsResult> {
    throw new Error('Method not implemented.')
  }

  async fetchPublicationsOfProject(): Promise<PublicationOfProject[]> {
    throw new Error('Method not implemented.')
  }

  async fetchPublications(): Promise<PublicationPaginatedResult> {
    throw new Error('Method not implemented.')
  }

  async fetchPublication(): Promise<Publication> {
    throw new Error('Method not implemented.')
  }

  async fetchPublicationBySlug(): Promise<Publication> {
    throw new Error('Method not implemented.')
  }

  async sendUpdatePublication(): Promise<Publication> {
    throw new Error('Method not implemented.')
  }

  async sendUpdatePublicationStat(): Promise<Publication> {
    throw new Error('Method not implemented.')
  }

  publicationSortingInfo(): BackendSortingInfo {
    throw new Error('Method not implemented.')
  }

  strategySortingInfo(): BackendSortingInfo {
    throw new Error('Method not implemented.')
  }

  async reportNotebookComment(): Promise<NotebookComment | null> {
    throw new Error('Method not implemented.')
  }

  async editNotebookComment(): Promise<NotebookComment | null> {
    throw new Error('Method not implemented.')
  }

  async sendAddNotebookCommentReaction(): Promise<NotebookComment | null> {
    throw new Error('Method not implemented.')
  }

  async sendRemoveNotebookCommentReaction(): Promise<NotebookComment | null> {
    throw new Error('Method not implemented.')
  }

  async deleteNotebookComment(): Promise<NotebookComment | null> {
    throw new Error('Method not implemented.')
  }

  async addNotebookComment(): Promise<NotebookComment | null> {
    throw new Error('Method not implemented.')
  }

  async fetchNotebookComments(): Promise<PaginatedResult<NotebookComment>> {
    throw new Error('Method not implemented.')
  }

  isCloneProjectSupported(): boolean {
    return false
  }

  async sendCloneProject(): Promise<Project> {
    throw new Error('Method not implemented.')
  }

  async updateProjectUserPermissions(): Promise<ProjectUserAccess> {
    throw new Error('Method not implemented.')
  }

  async updateProjectTeamPermissions(): Promise<ProjectTeamAccess> {
    throw new Error('Method not implemented.')
  }

  async toggleProjectPermissionAuthor(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateProjectOwner(): Promise<Project> {
    throw new Error('Method not implemented.')
  }

  async grantUserPermissions(): Promise<ProjectUserAccess> {
    throw new Error('Method not implemented.')
  }

  async revokeUserPermissions(): Promise<ProjectUserAccess> {
    throw new Error('Method not implemented.')
  }

  async grantTeamPermissions(): Promise<ProjectTeamAccess> {
    throw new Error('Method not implemented.')
  }

  async revokeTeamPermissions(): Promise<ProjectTeamAccess> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateProjectPublicity(projectId: string, isPublic: boolean): Promise<Project> {
    const data: PapillonProjectShareRequest = {
      isPublic,
      publicGroup: 'all',
      teams: {},
      users: {},
    }

    return this.fetch(`${this.prefix}/notebooks/${projectId}/share`, { method: 'PUT', sendJson: true, body: data })
  }

  async fetchTags(): Promise<Tag[]> {
    return []
  }

  async sendUpdateProjectTags(): Promise<Project> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateProjectThumbnail(projectId: string, data: FormData): Promise<Project> {
    const thumbnail = data.get('thumbnail')

    const latestProject = await this.fetchProject(projectId)

    if (!thumbnail || !(thumbnail instanceof Blob)) {
      return this.sendReplaceProject(projectId, {
        ...latestProject,
        preview: null,
        customPreview: false,
      })
    }

    // create data URI from thumbnail
    const dataURI = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject(new Error('FileReader returned invalid type'))
        }
      }
      reader.onerror = () => reject(new Error('FileReader failed'))
      reader.onabort = () => reject(new Error('FileReader aborted'))

      reader.readAsDataURL(thumbnail)
    })

    return this.sendReplaceProject(projectId, {
      ...latestProject,
      preview: dataURI,
      customPreview: true,
    })
  }

  isCollaboratorsSupported(): boolean {
    return false
  }

  isGitHubSupported(): boolean {
    return true
  }

  isGitHubCustomGlobalTokenSupported(): boolean {
    return true
  }

  isSlackSupported(): boolean {
    return false
  }

  isRunsSupported(): boolean {
    return false
  }

  isProjectsSupported(): boolean {
    return true
  }

  isTeamsSupported(): boolean {
    return false
  }

  isCodeCommentingSupported(): boolean {
    return true
  }

  isPricingSupported(): boolean {
    return false
  }

  isWalletSupported(): boolean {
    return false
  }

  isNewsroomSupported(): boolean {
    return false
  }

  isPublicationsSupported(): boolean {
    return false
  }

  isProjectRatingSupported(): boolean {
    return true
  }

  isProjectLicenseSupported(): boolean {
    return false
  }

  isProjectAnalysisSupported(): boolean {
    return false
  }

  isExecutingMainFileSupported(): boolean {
    return true
  }

  isExecutionWithParametersSupported(): boolean {
    return true
  }

  isUpdatingMainFileSupported(): boolean {
    return true
  }

  isNotebookConnectionSupported(): boolean {
    return false
  }

  isEventsSupported(): boolean {
    return false
  }

  isProjectDescriptionSupported(): boolean {
    return false
  }

  isProjectCategoriesSupported(): boolean {
    return false
  }

  isNotificationsSupported(): boolean {
    return false
  }

  isAboutProjectSupported(): boolean {
    return false
  }

  isAdminSupported(): boolean {
    return false
  }

  isInviteSupported(): boolean {
    return false
  }

  isExportProjectSupported(): boolean {
    return false
  }

  isEthereumIDESupported(): boolean {
    return true
  }

  isNewsletterSubscriptionSupported(): boolean {
    return true
  }

  //
  projectSortingInfo(): BackendSortingInfo {
    const defaultField: BackendSortingOption = {
      field: 'updatedAt',
      title: 'Last update',
      internalField: 'updatedAt',
      shortTitle: 'Updated',
    }

    return {
      fields: [
        {
          field: 'name',
          title: 'Name',
          internalField: 'name',
        },
        {
          field: 'createdAt',
          title: 'Creation date',
          internalField: 'createdAt',
          shortTitle: 'Created',
        },
        defaultField,
        {
          field: 'totalRating',
          title: 'Rating',
          internalField: 'totalRating',
        },
      ],
      defaultField,
    }
  }

  async fetchAppStatus(): Promise<AppStatus> {
    return {}
  }

  async fetchSelf(): Promise<Self> {
    const [self, profile]: [Self, Profile] = await Promise.all([this.fetch(`${this.prefix}/self`), this.fetch(`${this.prefix}/userProfile`)])

    this.cache.userId = self.user?.id
    self.apiKey = profile.apiKey
    // Papi's User response contains only `name` as a `firstName` which is against our User interface
    if (self.user) {
      self.user.firstName = profile.firstName
      self.user.isDiscoverable = false
    }

    return self
  }

  async fetchEnvironments(): Promise<Environments> {
    throw new Error('Method not implemented.')
  }

  async fetchRamTiers(): Promise<RamTiersResponse> {
    throw new Error('Method not implemented.')
  }

  async sendLogOut(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateAboutPage(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchAboutPage(_userId: string): Promise<UserAboutPage> {
    throw new Error('Method not implemented.')
  }

  async fetchSubscriptionHistory(): Promise<Subscription[]> {
    throw new Error('Method not implemented.')
  }

  async fetchSubscription(): Promise<Subscription | null> {
    return null
  }

  async changeSubscription(): Promise<Subscription | null> {
    throw new Error('Method not implemented.')
  }

  async cancelSubscription(): Promise<Subscription | null> {
    throw new Error('Method not implemented.')
  }

  isSecretsSupported(): boolean {
    return false
  }

  isCollaborationConnectionSupported(): boolean {
    return true
  }

  isExecutionConnectionSupported(): boolean {
    return true
  }

  isContainerConnectionSupported(): boolean {
    return true
  }

  isScheduleEnabled(): boolean {
    return false
  }

  async fetchSecrets(): Promise<Secret[]> {
    throw new Error('Method not implemented.')
  }

  async saveSecret(_key: string, _secret: string): Promise<Secret> {
    throw new Error('Method not implemented.')
  }

  async deleteSecret(_id: string): Promise<Secret> {
    throw new Error('Method not implemented.')
  }

  async fetchProjects(opts: ProjectPaginationOptions): Promise<ProjectPaginatedResult> {
    let projects: Array<Project> = this.cache.cachedProjects

    projects = projects.map(project => ({
      ...project,
      permissions: mapPermissions(project.permissions, this.cache.userId === project.userId),
      creator: project.user,
      ownedAt: project.createdAt,
    }))

    if (new Date().getTime() - this.cache.cachedProjectsDate > PROJECTS_DEBOUNCE_MS) {
      projects = await this.fetch(`${this.prefix}/notebooks`)
      this.cache.cachedProjects = projects
      this.cache.cachedProjectsDate = new Date().getTime()
    }

    return searchProjects(projects, opts)
  }

  async fetchProjectUserPermissions(): Promise<PaginatedResult<ProjectUserAccess>> {
    throw new Error('Method not implemented.')
  }

  async fetchProjectTeamPermissions(): Promise<PaginatedResult<ProjectTeamAccess>> {
    throw new Error('Method not implemented.')
  }

  async fetchUser(username: string): Promise<UserInfo> {
    const { user }: { user: UserInfo } = await this.fetch(`${this.prefix}/publicProfile/${encodeURIComponent(username)}`)

    user.isDiscoverable = false

    return user
  }

  async fetchUsers(): Promise<UserInfo[]> {
    throw new Error('Method not implemented.')
  }

  async fetchProject(id: string): Promise<Project> {
    const project: Project = await this.fetch(`${this.prefix}/projects/${id}`)

    project.permissions = mapPermissions(project.permissions, this.cache.userId === project.userId)
    project.creator = project.user
    project.ownedAt = project.createdAt

    return project
  }

  async fetchProjectFiles(id: string, path?: string): Promise<Array<File>> {
    const data: ListFileResponse = await this.fetch(`${this.prefix}/projectFiles/${id}${path ? `?directory=${path}` : ''}`)
    return data.map(item => ({
      ...item.file,
      projectId: id,
      name: item.name,
      treeType: item.type,
      type: getFileType(item.name),
      id: item.type === 'directory' ? `${id}/${item.name}` : item.file.id,
    }))
  }

  async fetchProjectFileClonedIds(): Promise<ClonedFileIds> {
    throw new Error('Method not implemented.')
  }

  async fetchFileByPath(id: string, path: string): Promise<File | undefined> {
    const parentPath = getFileParentPath(path)
    const items = await this.fetchProjectFiles(id, parentPath)

    return items.find(item => item.name === path)
  }

  async fetchFile(): Promise<File> {
    throw new Error('Method not implemented.')
  }

  async fetchFileExists(id: string, path: string): Promise<boolean> {
    return (await this.fetchFileByPath(id, path)) !== undefined
  }

  async fetchDirectoryExists(id: string, path: string): Promise<boolean> {
    return (await this.fetchFileByPath(id, path)) !== undefined
  }

  async fetchProjectFileVersions(id: string, fileId: FileId): Promise<Array<FileVersion>> {
    const versions = await this.fetch(`${this.prefix}/projectFileVersions/${id}/${fileId}`)
    return versions.map(mapFileVersion)
  }

  async fetchProjectDirectories(id: string, filter?: Array<string>): Promise<Array<File>> {
    const data: ListFileResponse = await this.fetch(`${this.prefix}/projectDirectories/${id}${filter ? `?directory=${filter.join(',')}` : ''}`)
    return data.map(item => ({
      ...item.file,
      projectId: id,
      name: item.name,
      treeType: item.type,
      type: getFileType(item.name),
      id: item.type === 'directory' ? `${id}/${item.name}` : item.file.id,
    }))
  }

  async fetchLatestProjectFileVersion(id: string, fileId: FileId): Promise<FileVersion> {
    const data = await this.fetch(`${this.prefix}/projectFileVersions/${id}/${fileId}/latest`)
    return mapFileVersion(data)
  }

  async fetchLatestProjectFileVersionRaw(id: string, fileId: FileId): Promise<Blob> {
    const version = await this.fetchLatestProjectFileVersion(id, fileId)

    return new Blob([version.data ?? ''])
  }

  async fetchLatestProjectFileVersionUrl(id: string, fileId: FileId): Promise<string> {
    const data = await this.fetchLatestProjectFileVersionRaw(id, fileId)
    const blob = new Blob([data], { type: 'application/octet-stream' })

    return URL.createObjectURL(blob)
  }

  async fetchProjectFileVersion(): Promise<FileVersion> {
    throw new Error('Method not implemented.')
  }

  sendCreateProject(data: Partial<Project>): Promise<Project> {
    const body = { ...data, type: CodingType.script }

    return this.fetch(`${this.prefix}/projects`, { method: 'POST', sendJson: true, body })
  }

  async sendCreateProjectFile(id: string, { name, data, language }: Partial<File>): Promise<File> {
    const file = await this.fetch(`${this.prefix}/projectFiles/${id}`, { method: 'POST', sendJson: true, body: { name, data, language } })
    await this.internalSendCreateProjectFileVersion(id, file.id, '', false, [])

    return { ...file, projectId: id, name: file.name, treeType: 'file', id: file.id }
  }

  async sendCreateProjectFileVersion(): Promise<FileVersion> {
    throw new Error('Method not implemented.')
  }

  private async internalSendCreateProjectFileVersion(
    id: string,
    fileId: FileId,
    data: string,
    _binary: boolean,
    threads?: CodeComments,
  ): Promise<FileVersion> {
    const body = {
      data,
      threadPositions: encodeThreads(threads),
    }

    const mipasaVersion = await this.fetch(`${this.prefix}/projectFileVersions/${id}/${fileId}`, {
      method: 'POST',
      sendJson: true,
      body,
    })
    return mapFileVersion(mipasaVersion)
  }

  async sendUpdateProjectFile(projectId: string, data: Partial<File>): Promise<File> {
    const file: File = await this.fetch(`${this.prefix}/projectFiles/${projectId}/${data.id as string}`, {
      method: 'PUT',
      sendJson: true,
      body: data,
    })

    return { ...file, projectId, treeType: 'file' }
  }

  async sendDeleteProjectFile(projectId: string, fileId: FileId): Promise<void> {
    await this.fetch(`${this.prefix}/projectFiles/${projectId}/${fileId}`, { method: 'DELETE' })
  }

  async sendDuplicateProjectFile(): Promise<File> {
    throw new Error('Method not implemented.')
  }

  sendReplaceProject(id: string, data: Project): Promise<Project> {
    return this.fetch(`${this.prefix}/projects/${id}`, { method: 'PUT', sendJson: true, body: data })
  }

  async sendDeleteProject(id: string): Promise<void> {
    await this.fetch(`${this.prefix}/projects/${id}`, { method: 'DELETE' })
  }

  async sendReplaceProjectDirectory(id: string, oldName: string, newName: string): Promise<Array<File>> {
    const body = { from: `${oldName}`, to: `${newName}` }

    const data: ListFileResponse = await this.fetch(`${this.prefix}/projectDirectories/${id}`, { method: 'PUT', sendJson: true, body })
    return data.map(item => ({ ...item.file }))
  }

  private async deleteSubPath(id: string, path: string): Promise<void> {
    const entries = await this.fetchProjectFiles(id, path)

    await Promise.all(
      entries.map(entry => {
        switch (entry.treeType) {
          case 'file':
            return this.sendDeleteProjectFile(id, entry.id)
          case 'directory':
            return this.deleteSubPath(id, entry.name)
          default:
            return assertUnreachable(entry.treeType)
        }
      }),
    )
  }

  async sendDeleteProjectDirectory(id: string, path: string): Promise<void> {
    return this.deleteSubPath(id, path)
  }

  async sendUpvoteProject(projectId: string): Promise<number> {
    const response = await this.fetch(`${this.prefix}/projectReactions/${projectId}/up`, { method: 'POST', body: '' })

    return response?.value || 0
  }

  canExecuteFile(): boolean {
    return true
  }

  async fetchExecutions(id: string, fileId: string): Promise<Array<ExecutionSummary>> {
    const list: Array<Execution> = await this.fetch(`${this.prefix}/projectFileExecutions/${id}/${fileId}`)
    return list.map(item => {
      const updated: ExecutionSummary = { ...item }

      delete updated.executionResult
      delete updated.executionStacktrace
      return updated
    })
  }

  async fetchPaginatedExecutions(): Promise<PaginatedResult<Execution>> {
    throw new Error('Method not implemented')
  }

  async fetchExecution(id: string, fileId: string, executionId: string): Promise<Execution> {
    const execution = await this.fetch(`${this.prefix}/projectFileExecutions/${id}/${fileId}/${executionId}`)

    if (!execution.executionResult) {
      delete execution.executionResult
    }
    return execution
  }

  sendStartExecution(id: string, fileId: string, settings?: ExecutionSettings): Promise<Execution> {
    const params = settings ? { settings } : null

    return this.fetch(`${this.prefix}/projectFileExecutions/${id}/${fileId}`, { method: 'POST', sendJson: true, body: params })
  }

  sendStopExecution(id: string, fileId: string, executionId: string): Promise<void> {
    return this.fetch(`${this.prefix}/projectFileExecutions/${id}/${fileId}/${executionId}`, { method: 'DELETE' })
  }

  async getNotificationsCount(): Promise<number | undefined> {
    const headerCounters = await this.fetch(`${this.prefix}/admin/headerCounters`)

    return headerCounters?.countNotifications
  }

  createCollaborationConnection(fileId: FileId): BackendCollaborationConnection {
    return new PapillonCollaborationConnection(fileId)
  }

  createExecutionConnection(executionId: ExecutionId): BackendExecutionConnection {
    return new PapillonExecutionConnection(executionId)
  }

  createContainerConnection(projectId: string): BackendContainerConnection {
    return new PapillonContainerConnection(projectId)
  }

  fetchBalance(): Promise<Balance> {
    return this.fetch(`${this.prefix}/balance`)
  }

  fetchProfile(): Promise<Profile> {
    return this.fetch(`${this.prefix}/userProfile`)
  }

  fetchSettings(): Promise<Record<string, any>> {
    return this.fetch(`${this.prefix}/preferences`)
  }

  sendUpdateSettings(settings: Record<string, any>): Promise<void> {
    return this.fetch(`${this.prefix}/preferences`, { method: 'PUT', sendJson: true, body: settings })
  }

  async fetchNotebookAnalysisProvenance(): Promise<NotebookAnalysis> {
    throw new Error('Method not implemented.')
  }

  async fetchNotebookAnalysisInheritance(): Promise<NotebookAnalysis> {
    throw new Error('Method not implemented.')
  }

  createNotebookConnection(_projectId: string, _fileId: string, _params: BackendNotebookConnectionParameters): BackendNotebookConnection {
    throw new Error('Method not implemented.')
  }

  createNotebookEmbedConnection(_embedId: string, _params: BackendNotebookConnectionParameters): BackendNotebookConnection {
    throw new Error('Method not implemented.')
  }

  createCellEmbedConnection(
    _projectId: string,
    _fileId: string,
    _cellId: string,
    _outputIndex?: string,
    _isDebugMode?: BackendCellEmbedConnectionMode,
  ): BackendCellEmbedConnection {
    throw new Error('Method not implemented.')
  }

  async fetchScheduledNotebooks(): Promise<PaginatedResult<ScheduledNotebook>> {
    throw new Error('Method not implemented.')
  }

  async fetchUserServers(): Promise<PaginatedResult<UserServer>> {
    throw new Error('Method not implemented.')
  }

  async sendStopUserServer(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchNotebookSchedule(_projectId: string, _fileId: string): Promise<ScheduledExecution> {
    throw new Error('Method not implemented.')
  }

  async sendNotebookSchedule(_projectId: string, _fileId: string, _data: ScheduledExecutionPatch): Promise<ScheduledExecution> {
    throw new Error('Method not implemented.')
  }

  fileUrlByName(): string {
    throw new Error('Method not implemented.')
  }

  isFileUrlByNameSupported(): boolean {
    return false
  }

  async sendAddEventEntry(): Promise<EventEntry> {
    throw new Error('Method not implemented.')
  }

  async fetchDocumentation(): Promise<Array<DocumentationSection>> {
    return []
  }

  async sendUpdateDocumentationSection(): Promise<DocumentationSection> {
    throw new Error('Method not implemented.')
  }

  async fetchNewsroomCount(): Promise<number> {
    return 0
  }

  async fetchNewsroom(): Promise<Array<NewsroomEntry>> {
    return []
  }

  async fetchNewsroomEntry(): Promise<NewsroomEntry> {
    throw new Error('Method not implemented.')
  }

  async sendCreateNewsroomEntry(): Promise<NewsroomEntry> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateNewsroomEntry(): Promise<NewsroomEntry> {
    throw new Error('Method not implemented.')
  }

  async sendDeleteNewsroomEntry(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  createNotificationsConnection(): BackendNotificationsConnection {
    throw new Error('Method not implemented.')
  }

  createProjectImportConnection(): BackendProjectImportConnection {
    throw new Error('Method not implemented.')
  }

  createCollaboratorLobbyConnection(): BackendCollaboratorLobbyConnection {
    throw new Error('Method not implemented.')
  }

  createFileChannelConnection(): BackendFileChannelConnection {
    throw new Error('Method not implemented.')
  }

  createProjectFilesConnection(): BackendProjectFilesConnection {
    throw new Error('Method not implemented.')
  }

  createTrackingConnection(): BackendTrackingConnection {
    throw new Error('Method not implemented.')
  }

  createTradingTransactionsConnection(): BackendTradingTransactionsConnection {
    throw new Error('Method not implemented.')
  }

  isNotificationsWSSupported(): boolean {
    return false
  }

  async fetchToken(): Promise<UserToken> {
    try {
      const self: Self = await this.fetch(`${this.prefix}/self`)

      return { token: self.token }
    } catch (e) {
      captureError(e)
      return { token: undefined }
    }
  }

  async createOAuthState(): Promise<string> {
    throw new Error('Method not implemented.')
  }

  async verifySlackCode(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async disconnectSlack(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchSlackChannels(): Promise<CursorPaginatedResponse<BackendSlackChannel>> {
    throw new Error('Method not implemented.')
  }

  async fetchProjectAccessRequests(): Promise<PaginatedResult<ProjectAccessRequest>> {
    throw new Error('Method not implemented.')
  }

  async fetchProjectAccessRequest(): Promise<ProjectAccessRequest> {
    throw new Error('Method not implemented.')
  }

  async fetchMyProjectAccessRequest(): Promise<ProjectAccessRequest> {
    throw new Error('Method not implemented.')
  }

  async sendCreateProjectAccessRequest(): Promise<ProjectAccessRequest> {
    throw new Error('Method not implemented.')
  }

  async sendDeleteProjectAccessRequest(): Promise<ProjectAccessRequest> {
    throw new Error('Method not implemented.')
  }

  async sendApproveProjectAccessRequest(): Promise<ProjectAccessRequest> {
    throw new Error('Method not implemented.')
  }

  async sendRejectProjectAccessRequest(): Promise<ProjectAccessRequest> {
    throw new Error('Method not implemented.')
  }

  async fetchTeams(): Promise<PaginatedResult<Team>> {
    throw new Error('Method not implemented.')
  }

  async fetchTeam(): Promise<Team> {
    throw new Error('Method not implemented.')
  }

  async sendCreateTeam(): Promise<Team> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateTeam(): Promise<Team> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateTeamThumbnail(): Promise<Team> {
    throw new Error('Method not implemented.')
  }

  async sendDeleteTeam(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchTeamMembers(): Promise<PaginatedResult<TeamMember>> {
    throw new Error('Method not implemented.')
  }

  async sendCreateTeamMember(): Promise<TeamMember> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateTeamMember(): Promise<TeamMember> {
    throw new Error('Method not implemented.')
  }

  async sendRemoveTeamMember(): Promise<TeamMember> {
    throw new Error('Method not implemented.')
  }

  async fetchTeamJoinRequests(): Promise<PaginatedResult<TeamJoinRequest>> {
    throw new Error('Method not implemented.')
  }

  async sendRespondTeamJoinRequest(): Promise<TeamJoinRequest> {
    throw new Error('Method not implemented.')
  }

  async sendCreateTeamJoinRequest(): Promise<TeamJoinRequest> {
    throw new Error('Method not implemented.')
  }

  async sendCancelTeamJoinRequest(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchActiveTeamJoinRequest(): Promise<TeamJoinRequest> {
    throw new Error('Method not implemented.')
  }

  async sendLeaveTeam(): Promise<Team> {
    throw new Error('Method not implemented.')
  }

  async fetchTeamProjects(): Promise<PaginatedResult<TeamProject>> {
    throw new Error('Method not implemented.')
  }

  async fetchTeamActivity(): Promise<PaginatedResult<FeedEntry>> {
    throw new Error('Method not implemented.')
  }

  teamSortingInfo(): BackendSortingInfo {
    throw new Error('Method not implemented.')
  }

  teamDiscoveryInfo(): BackendSelectInfo<TeamDiscovery> {
    throw new Error('Method not implemented.')
  }

  teamPermissionInfo(): BackendPermissionInfo<TeamMemberPermission> {
    throw new Error('Method not implemented.')
  }

  async fetchProjectInvites(): Promise<ResponseCollection<ProjectInvite>> {
    throw new Error('Method not implemented.')
  }

  async sendCreateProjectInvite(): Promise<ProjectInvite> {
    throw new Error('Method not implemented.')
  }

  async sendDeleteProjectInvite(): Promise<ProjectInvite> {
    throw new Error('Method not implemented.')
  }

  async fetchTeamInvites(): Promise<ResponseCollection<TeamInvite>> {
    throw new Error('Method not implemented.')
  }

  async sendCreateTeamInvite(): Promise<TeamInvite> {
    throw new Error('Method not implemented.')
  }

  async sendDeleteTeamInvite(): Promise<TeamInvite> {
    throw new Error('Method not implemented.')
  }

  async sendCreateInvite(): Promise<Invite> {
    throw new Error('Method not implemented.')
  }

  async fetchInvite(): Promise<Invite> {
    throw new Error('Method not implemented.')
  }

  fetch(...params: Parameters<typeof authorizedJsonFetch>) {
    const opts = params[1] || {}

    opts.signal = this.signal
    opts.backend = this.networkBackend
    opts.onProgress = this.uploadProgress
    params[1] = opts

    return authorizedJsonFetch(...params)
  }

  withSignal(signal?: AbortSignal) {
    const newBackend: typeof this = Object.assign(Object.create(Object.getPrototypeOf(this)), this)

    newBackend.signal = signal
    return newBackend
  }

  withNetworkBackend(type?: 'xhr' | 'fetch' | undefined): Backend {
    const newBackend: typeof this = Object.assign(Object.create(Object.getPrototypeOf(this)), this)

    newBackend.networkBackend = type
    return newBackend
  }

  withUploadProgress(cb: AuthorizedRequestParams['onProgress']): Backend {
    const newBackend: typeof this = Object.assign(Object.create(Object.getPrototypeOf(this)), this)

    newBackend.uploadProgress = cb
    return newBackend
  }

  async fetchWallet(): Promise<WalletState> {
    return {
      balance: 0,
      unbounded_balance: 0,
      entries: [],
      totalEntries: 0,
      totalPages: 1,
      page: 1,
      perPage: 0,
    }
  }

  async sendTransferUnits(): Promise<WalletBalances> {
    throw new Error('Method not implemented.')
  }

  fetchWalletUnitPurchaseInfo(): Promise<WalletUnitPurchaseInfo> {
    throw new Error('Method not implemented.')
  }

  sendCreateWalletUnitPurchaseSession(): Promise<WalletUnitPurchaseSession> {
    throw new Error('Method not implemented.')
  }

  isDataRequestSupported(): boolean {
    return false
  }

  async sendDataRequest(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async sendUploadContent(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async sendUploadRequest(): Promise<FileUploadItem> {
    throw new Error('Method not implemented.')
  }

  isFollowersSupported(): boolean {
    return false
  }

  async fetchFollowers(): Promise<PaginatedResult<UserFollow>> {
    throw new Error('Method not implemented.')
  }

  async sendFollowUser(): Promise<UserFollow> {
    throw new Error('Method not implemented.')
  }

  async sendUnfollowUser(): Promise<UserFollow> {
    throw new Error('Method not implemented.')
  }

  async fetchGitHubConnection(): Promise<GitHubConnection> {
    const self = await this.fetchSelf()

    return {
      isConnected: !!(self.user && self.user.githubUsername !== undefined),
      userName: self.user?.githubUsername || null,
      avatar: self.user?.githubAvatar || null,
    }
  }

  async fetchExchangeConnections(): Promise<ExchangeConnections> {
    throw new Error('Method not implemented.')
  }

  async sendConnectToGitHub(code: string, redirectUri: string): Promise<GitHubConnection> {
    const body = {
      code,
      redirectUrl: redirectUri,
    }

    const self: Self = await this.fetch(`${this.prefix}/oauth/github`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return {
      isConnected: !!(self.user && self.user.githubUsername !== undefined),
      userName: self.user?.githubUsername || null,
      avatar: self.user?.githubAvatar || null,
    }
  }

  async sendSubscribeToNewsletter(firstName: string, lastName: string, email: string): Promise<void> {
    await this.fetch(`${this.prefix}/newsletterSubscriptions`, {
      sendJson: true,
      body: { email, firstName, lastName },
      method: 'POST',
      noAuth: true,
    })
  }

  async sendDisconnectFromGitHub(): Promise<void> {
    await this.fetch(`${this.prefix}/oauth/github/unlink`)
  }

  async sendLinkExchange(): Promise<ExchangeConnections> {
    throw new Error('Method not implemented.')
  }

  async sendUnlinkExchange(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchRatePlans(): Promise<Array<RatePlan>> {
    throw new Error('Method not implemented.')
  }

  async syncProject(): Promise<ProjectSyncResponse> {
    throw new Error('Method not implemented.')
  }

  async sendCreateProjectSyncConfig(): Promise<ProjectSyncResponse> {
    throw new Error('Method not implemented.')
  }

  async sendDeleteProjectSyncConfig(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  fetchBrowseSyncRemote(): Promise<BrowseSyncRemoteResponse> {
    throw new Error('Method not implemented.')
  }

  fetchExportProjectURL(): string {
    throw new Error('Method not implemented.')
  }

  fetchExportProjectFile(): Promise<Blob> {
    throw new Error('Method not implemented.')
  }

  fetchExportProjectFileURL(): string {
    throw new Error('Method not implemented.')
  }

  async fetchNotifications(): Promise<PaginatedResult<NotificationEntry>> {
    throw new Error('Method not implemented.')
  }

  async sendMarkNotificationsAsRead(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchTagSubscriptions(): Promise<ResponseCollection<TagSubscription>> {
    throw new Error('Method not implemented.')
  }

  async sendSubscribeToTag(): Promise<TagSubscription> {
    throw new Error('Method not implemented.')
  }

  async sendUnsubscribeFromTag(): Promise<TagSubscription> {
    throw new Error('Method not implemented.')
  }

  async sendAIGenerate(): Promise<Array<AIGeneratedEntry>> {
    throw new Error('Method not implemented.')
  }

  async sendAIGenerateCodeCell(): Promise<AIGeneratedEntry> {
    throw new Error('Method not implemented.')
  }

  async sendAIGenerateSQL(): Promise<AIGeneratedCode> {
    throw new Error('Method not implemented.')
  }

  async sendAIGenerateChart(): Promise<AIGeneratedCode> {
    throw new Error('Method not implemented.')
  }

  async sendAIGeneratePublicationsQuery(): Promise<AIGeneratedPublicationsQuery> {
    throw new Error('Method not implemented.')
  }

  async sendCreateTradingTransaction(): Promise<TradingTransaction> {
    throw new Error('Method not implemented.')
  }

  async fetchTradingTransaction(): Promise<TradingTransaction> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateTeamOwner(): Promise<Team> {
    throw new Error('Method not implemented.')
  }

  async sendRemovePublicationClap(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async sendAddPublicationClap(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchTips(): Promise<ResponseCollection<Tip>> {
    throw new Error('Method not implemented.')
  }

  async adminFetchActivity(): Promise<PaginatedResult<AdminActivityLog>> {
    throw new Error('Method not implemented.')
  }

  async adminFetchUser(): Promise<AdminUser> {
    throw new Error('Method not implemented.')
  }

  async adminFetchUsers(): Promise<PaginatedResult<AdminUser>> {
    throw new Error('Method not implemented.')
  }

  adminFetchFeatures(): Promise<AdminFeature[]> {
    throw new Error('Method not implemented.')
  }

  adminFetchRoles(): Promise<AdminRole[]> {
    throw new Error('Method not implemented.')
  }

  adminSendCreateRole(): Promise<AdminRole> {
    throw new Error('Method not implemented.')
  }

  adminSendUpdateRole(): Promise<AdminRole> {
    throw new Error('Method not implemented.')
  }

  adminSendDeleteRole(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  adminFetchUserRoles(): Promise<AdminRole[]> {
    throw new Error('Method not implemented.')
  }

  adminSendAddUserRole(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  adminSendRemoveUserRole(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async adminSendUserRole(): Promise<AdminUser> {
    throw new Error('Method not implemented.')
  }

  async adminSendUserSubscription(): Promise<AdminUser> {
    throw new Error('Method not implemented.')
  }

  async adminSendUserWalletTransaction(): Promise<AdminWalletTransaction> {
    throw new Error('Method not implemented.')
  }

  async adminFetchUserWalletTransactions(): Promise<PaginatedResult<AdminWalletTransaction>> {
    throw new Error('Method not implemented.')
  }

  async adminSendImpersonateUser(): Promise<Self> {
    throw new Error('Method not implemented.')
  }

  async adminSendUnimpersonate(): Promise<Self> {
    return this.fetch(`${this.prefix}/admin/mask`, { method: 'DELETE' })
  }

  async adminSendSuspend(_userId: string, _message: string): Promise<AdminUser> {
    throw new Error('Method not implemented.')
  }

  async adminSendUnsuspend(_userId: string, _message: string): Promise<AdminUser> {
    throw new Error('Method not implemented.')
  }

  async adminFetchUserActionLogs(_opts: AdminFetchUserActionLogsOption): Promise<PaginatedResult<AdminUserActionLog>> {
    throw new Error('Method not implemented.')
  }

  async fetchExternalPublicationSettings(): Promise<ExternalPublishSettings> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateExternalPublicationSettings(): Promise<ExternalPublishSettings> {
    throw new Error('Method not implemented.')
  }

  async fetchPublicationSchedule(): Promise<PublicationSchedule> {
    throw new Error('Method not implemented.')
  }

  async sendUpdatePublicationSchedule(): Promise<PublicationSchedule> {
    throw new Error('Method not implemented.')
  }

  async fetchReferrals(): Promise<Array<Referral>> {
    return []
  }

  async fetchReferralUsers(): Promise<PaginatedResult<ReferralUser>> {
    throw new Error('Method not implemented.')
  }

  async sendCreateReferral(): Promise<Referral> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateReferral(): Promise<Referral> {
    throw new Error('Method not implemented.')
  }

  async sendDeleteReferral(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  generateInstanceExportURL(): string {
    throw new Error('Method not implemented.')
  }

  async sendSaveInstanceExport(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async fetchTradingAssets(): Promise<TradingAsset[]> {
    throw new Error('Method not implemented.')
  }

  async fetchTradingAsset(): Promise<TradingAsset> {
    throw new Error('Method not implemented.')
  }

  async fetchTradingAssetData(): Promise<TradingAssetData> {
    throw new Error('Method not implemented.')
  }

  async fetchTradingBalance(): Promise<TradingBalance[]> {
    throw new Error('Method not implemented.')
  }

  async fetchTradingDemoMarkets(): Promise<Dictionary<Market>> {
    throw new Error('Method not implemented.')
  }

  async fetchTradingDemoTickers(): Promise<Tickers> {
    throw new Error('Method not implemented.')
  }

  async fetchSubscriptionStats(): Promise<SubscriptionStats> {
    throw new Error('Method not implemented.')
  }

  async fetchSignupStats(): Promise<SignupStat[]> {
    throw new Error('Method not implemented.')
  }

  async adminFetchSubsctiptionStats(): Promise<AdminSubscriptionStatsResult> {
    throw new Error('Method not implemented.')
  }

  async adminFetchTradingBotChannels(): Promise<TradingBotChannel[]> {
    throw new Error('Method not implemented.')
  }

  async adminSendUpdateTradingBotChannel(): Promise<TradingBotChannel> {
    throw new Error('Method not implemented.')
  }

  async adminSendSendTradingBotChannelPeriodicUpdate(): Promise<TradingBotChannel> {
    throw new Error('Method not implemented.')
  }

  async adminSendRefreshTradingBotChannel(): Promise<TradingBotChannel> {
    throw new Error('Method not implemented.')
  }

  async adminSendDeleteTradingBotChannel(): Promise<TradingBotChannel> {
    throw new Error('Method not implemented.')
  }

  async adminFetchWatermarkUrl(): Promise<string> {
    throw new Error('Method not implemented.')
  }

  async adminSendWatermark(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async adminDeleteWatermark(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async adminFetchRatePlans(): Promise<AdminRatePlan[]> {
    throw new Error('Method not implemented.')
  }

  async adminFetchRatePlan(): Promise<AdminRatePlan> {
    throw new Error('Method not implemented.')
  }

  async adminSendCreateRatePlan(): Promise<AdminRatePlan> {
    throw new Error('Method not implemented.')
  }

  async adminSendUpdateRatePlan(): Promise<AdminRatePlan> {
    throw new Error('Method not implemented.')
  }

  async adminSendDeleteRatePlan(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async adminFetchJupyterUsages(): Promise<AdminJupyterUsageStat[]> {
    throw new Error('Method not implemented.')
  }

  async fetchFileEmbed(): Promise<FileEmbed> {
    throw new Error('Method not implemented.')
  }

  async sendUpdateFileEmbed(): Promise<FileEmbed> {
    throw new Error('Method not implemented.')
  }

  async sendRevokeFileEmbed(): Promise<void> {
    throw new Error('Method not implemented.')
  }
}
