import { ThemeMode, ThemeName } from '@unbounded/unbounded-components'
import type { Backend, ExchangeBackend, UnboundedBackend } from '~api'
import papillonLogo from '~assets/logo.svg'
import cryptoLogo from '~assets/logo_crypto.svg'
import cryptoLogoWithName from '~assets/logo_crypto_with_name.svg'
import dpasaLogo from '~assets/logo_dpasa.svg'
import dpasaLogoWithName from '~assets/logo_dpasa_with_name.svg'
import mipasaLogoWithName from '~assets/logo_mipasa_with_name.svg'
import papillonLogoWithName from '~assets/logo_with_name.svg'
import mipasaLogo from '~assets/mipasa_brand.svg'
import unboundedLogo from '~assets/unbounded-logo.svg'

export enum Platform {
  mipasa = 'mipasa',
  papillon = 'papillon',
  crypto = 'crypto',
  dpasa = 'dpasa',
}

interface ParentPlatform {
  name: string
  logo: string
  url: string

  linkAboutUs?: string
  linkServices?: string
  linkSolutions?: string

  linkTermsOfUse?: string
  linkPrivacyPolicy?: string
  linkContactUs?: string
}

interface PlatformMeta {
  name: string
  title: string
  logoWithName: string
  logo: string
  description?: string
  themes: Record<ThemeMode, ThemeName>
  themeDefaultMode: ThemeMode
  backend: Backend
  partOf: ParentPlatform
}

const MIPASA_PARENT_PLATFORM: ParentPlatform = {
  name: 'MiPasa',
  logo: mipasaLogoWithName,
  url: 'https://www.mipasa.com/',

  linkAboutUs: 'https://www.mipasa.com/about-us',
  linkServices: 'https://www.mipasa.com/services',
  linkSolutions: 'https://www.mipasa.com/solutions',

  linkTermsOfUse: 'https://www.mipasa.com/terms-of-use',
  linkPrivacyPolicy: 'https://www.mipasa.com/privacy-policy',
  linkContactUs: 'https://www.mipasa.com/contact-us',
}

const UNBOUNDED_PARENT_PLATFORM: ParentPlatform = {
  name: 'Unbounded',
  logo: unboundedLogo,
  url: 'https://unbounded.mipasa.com/',

  linkAboutUs: 'https://unbounded.mipasa.com/about',
  linkServices: 'https://unbounded.mipasa.com/services',
  linkSolutions: 'https://unbounded.mipasa.com/solutions',

  linkTermsOfUse: 'https://unbounded.mipasa.com/terms',
  linkPrivacyPolicy: 'https://unbounded.mipasa.com/privacy',
  linkContactUs: 'https://unbounded.mipasa.com/contact',
}

export const platformMeta: Record<Platform, PlatformMeta> = {
  [Platform.mipasa]: {
    name: 'MiPasa',
    title: 'Analytics for Everyone — Anytime, Anywhere™',
    logoWithName: mipasaLogoWithName,
    logo: mipasaLogo,
    backend: null as unknown as Backend,
    themes: {
      light: ThemeName.mipasaLight,
      dark: ThemeName.mipasaDark,
    },
    themeDefaultMode: ThemeMode.light,
    partOf: MIPASA_PARENT_PLATFORM,
  },
  [Platform.papillon]: {
    name: 'Papillon',
    title: 'Developer Freedom',
    description: 'Developer Freedom — by Unbounded Network',
    logoWithName: papillonLogoWithName,
    logo: papillonLogo,
    backend: null as unknown as Backend,
    themes: {
      light: ThemeName.papillonLight,
      dark: ThemeName.papillonDark,
    },
    themeDefaultMode: ThemeMode.dark,
    partOf: MIPASA_PARENT_PLATFORM,
  },
  [Platform.crypto]: {
    name: 'Pasa Network',
    title: 'Verifiable Crypto Flows',
    logoWithName: cryptoLogoWithName,
    logo: cryptoLogo,
    backend: null as unknown as Backend,
    themes: {
      light: ThemeName.pasaLight,
      dark: ThemeName.pasaDark,
    },
    themeDefaultMode: ThemeMode.dark,
    partOf: UNBOUNDED_PARENT_PLATFORM,
  },
  [Platform.dpasa]: {
    name: 'Kalk',
    title: 'Solving Crypto Economic Challenges',
    logoWithName: dpasaLogoWithName,
    logo: dpasaLogo,
    backend: null as unknown as Backend,
    themes: {
      light: ThemeName.dpasaLight,
      dark: ThemeName.dpasaDark,
    },
    themeDefaultMode: ThemeMode.dark,
    partOf: UNBOUNDED_PARENT_PLATFORM,
  },
}

export const CURRENT_PLATFORM_TYPE: Platform = (_BUILD_PLATFORM as Platform) || Platform.papillon

export const isMiPasa = () => CURRENT_PLATFORM_TYPE === Platform.mipasa
export const isPapillon = () => CURRENT_PLATFORM_TYPE === Platform.papillon
export const isCrypto = () => CURRENT_PLATFORM_TYPE === Platform.crypto
export const isDPasa = () => CURRENT_PLATFORM_TYPE === Platform.dpasa

export const CURRENT_PLATFORM = platformMeta[CURRENT_PLATFORM_TYPE]

// Telegram Mini App
let IS_TELEGRAM_MINI_APP = false
export const setIsTelegramMiniApp = (isTelegram: boolean) => {
  IS_TELEGRAM_MINI_APP = isTelegram
}
export const isTelegramMiniApp = () => IS_TELEGRAM_MINI_APP

// may be rewritten to contexts later. for now this is a stub
export const useBackend = () => CURRENT_PLATFORM.backend

// Unbounded Backend
let unboundedBackend: UnboundedBackend | undefined
export const useUnboundedBackend = () => unboundedBackend!
export const initializeUnboundedBackend = (backend: UnboundedBackend) => {
  unboundedBackend = backend
}

// Exchange Backend
let exchangeBackend: ExchangeBackend | undefined
export const useExchangeBackend = () => exchangeBackend!
export const initializeExchangeBackend = (backend: ExchangeBackend) => {
  exchangeBackend = backend
}

export const getPageTitle = (title: string) => `${title} — ${CURRENT_PLATFORM.name}${isTelegramMiniApp() ? ' (TMA)' : ''}`

// Not sure if should be here, but it must depend on the platform used
// get server URL, with SSR support
export const SERVER_URL = typeof window !== 'undefined' ? window.location.origin : process.env.SERVER_URL || 'https://papillon.unbounded.network'

// Application configuration. Specific per deployment.
export interface ApplicationConfig {
  integrations: {
    idm: {
      url: string
    }
    github: {
      client_id?: string
      enabled: boolean
    }
    slack: {
      client_id?: string
      enabled: boolean
    }
    exchanges: {
      enabled: boolean
    }
    unbounded: {
      url?: string
      enabled: boolean
    }
    telegram: {
      bot_username?: string
      bot_id?: string
      enabled: boolean
    }
    sentry: {
      enabled: boolean
      environment?: string
      tunnel?: string
      dsn?: string
    }
  }
  publication_providers: Array<'sage'>
}

export function getDefaultApplicationConfig(): ApplicationConfig {
  return {
    integrations: {
      idm: {
        url: 'http://localhost:3400',
      },
      github: {
        enabled: false,
      },
      slack: {
        enabled: false,
      },
      exchanges: {
        enabled: false,
      },
      unbounded: {
        enabled: false,
      },
      sentry: {
        enabled: false,
      },
      telegram: {
        enabled: false,
      },
    },
    publication_providers: [],
  }
}

let APPLICATION_CONFIG: ApplicationConfig = getDefaultApplicationConfig()

export function updateApplicationConfig(newConfig: ApplicationConfig) {
  APPLICATION_CONFIG = { ...APPLICATION_CONFIG, ...newConfig }
}

export const getApplicationConfig = () => APPLICATION_CONFIG
