import { ComputeFeature, Feature } from './backend'

export function isFeatureFlag(f: Feature | ComputeFeature): f is Feature {
  const features = Object.values(Feature) as string[]
  return features.includes(f)
}

export function isComputeFeature(f: Feature | ComputeFeature): f is ComputeFeature {
  const features = Object.values(ComputeFeature) as string[]
  return features.includes(f)
}
